<template>
   <div class="product-page">
      <div class="product-page-wrap pt-4">
         <div class="container container-sm">
            <div class="row">
               <div class="col-md-12">
                  <div class="products-carousel">
                     <b-carousel
                        style="text-shadow: 0px 0px 2px #000"
                        fade
                        controls
                        indicators
                        img-height="300"
                     >
                        <b-carousel-slide
                           :img-src="require('@/assets/images/banner_img2.jpg')"
                        ></b-carousel-slide>
                        <b-carousel-slide
                           :img-src="require('@/assets/images/banner_img1.jpg')"
                        ></b-carousel-slide>
                     </b-carousel>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-12">
                  <div class="list-heading">
                     <h4 class="mb-0">Our Products</h4>
                  </div>
               </div>
            </div>
            <div class="row">
               <div
                  class="col-xl-4 col-sm-6"
                  v-for="(data, index) in products"
                  :key="index"
               >
                  <div class="card">
                     <div class="card-body">
                        <div class="text-center">
                           <img
                              :src="`${data.image_url}`"
                              alt
                              class="feature-img mb-2"
                           />
                           <!-- <img
                    :src="`${shopsData[index].image}`"
                    alt
                    class="avatar-md mt-2 mb-4"
                  /> -->
                           <div class="media-body">
                              <h6 class="text-truncate">
                                 <a href="#" class="text-dark">{{
                                    data.title
                                 }}</a>
                              </h6>
                              <p class="text-muted">
                                 <i class="mdi mdi-account mr-1"></i>
                                 {{ data.title_np }}
                              </p>
                           </div>
                        </div>
                        <hr class="my-3" />
                        <div class="row text-center">
                           <div class="col-12">
                              <h5 class="mb-0 text-primary font-weight-bold">
                                 {{ data.interest_rate }}
                              </h5>
                              <p class="text-muted mb-4">Interest Rate</p>
                           </div>
                        </div>
                        <div class="row text-center">
                           <div class="col-6">
                              <b-button
                                 pill
                                 variant="primary"
                                 @click="applyNow(data.slug)"
                                 >Apply Now</b-button
                              >
                           </div>
                           <div class="col-6">
                              <b-button
                                 @click="viewDetail(index)"
                                 pill
                                 variant="outline-secondary"
                                 >View Details</b-button
                              >
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <b-modal
         id="modal-scrollable"
         scrollable
         :title="selectedProduct.title"
         ref="modal-scrollable"
         title-class="font-18"
      >
         <div
            class="detail-text"
            v-html="selectedProduct.short_description"
         ></div>
      </b-modal>
   </div>
</template>
<script>
import Promise from "../../../lib/mixins/ExtendedPromises";
import Api from "../../../repositories/products";
export default {
   name: "ProductPage",
   mixins: [Promise],
   components: {},

   data() {
      return {
         title: "Products",
         products: [],
         selectedProduct: {
            title: "",
            short_description: "",
         },
         shopsData: [
            {
               image: require("@/assets/images/companies/img-1.png"),
               title: "LCY Fixed Deposit",
               name: "Wayne McClain",
               products: 86,
               balance: "$12,456",
            },
            {
               image: require("@/assets/images/companies/img-2.png"),
               title: "Brendle's",
               name: "David Marshall",
               products: 72,
               balance: "$10,352",
            },
            {
               image: require("@/assets/images/companies/img-3.png"),
               title: "Tech Hifi",
               name: "Katia Stapleton",
               products: 75,
               balance: "$9,963",
            },
            {
               image: require("@/assets/images/companies/img-4.png"),
               title: "Lafayette",
               name: "Andrew Bivens",
               products: 65,
               balance: "$14,568",
            },
            {
               image: require("@/assets/images/companies/img-5.png"),
               title: "Packer",
               name: "Mae Rankin",
               products: 82,
               balance: "$16,445",
            },
            {
               image: require("@/assets/images/companies/img-6.png"),
               title: "Micro Design",
               name: " Brian Correa",
               products: 71,
               balance: "$11,523",
            },
            {
               image: require("@/assets/images/companies/img-7.png"),
               title: "Keeney's",
               name: "Dean Odom",
               products: 66,
               balance: "$13,478",
            },
            {
               image: require("@/assets/images/companies/img-8.png"),
               title: "Tech Hifi",
               name: "John McLeroy",
               products: 58,
               balance: "$14,654",
            },
            {
               image: require("@/assets/images/companies/img-8.png"),
               title: "Tech Hifi",
               name: "John McLeroy",
               products: 538,
               balance: "$14,654",
            },
            {
               image: require("@/assets/images/companies/img-8.png"),
               title: "Tech Hifi",
               name: "John McLeroy",
               products: 5383,
               balance: "$14,654",
            },
            {
               image: require("@/assets/images/companies/img-1.png"),
               title: "LCY Fixed Deposit",
               name: "Wayne McClain",
               products: 862,
               balance: "$12,456",
            },
            {
               image: require("@/assets/images/companies/img-1.png"),
               title: "LCY Fixed Deposit",
               name: "Wayne McClain",
               products: 8622,
               balance: "$12,456",
            },
         ],
      };
   },
   mounted() {
      this.getPrerequest();
   },
   methods: {
      getPrerequest() {
         const operation = this.response(Api.getPreRequest());
         operation
            .then((data) => {
               if (operation.isFulfilled()) {
                  this.products = data.products;
               }
            })
            .catch((err) => {
               console.log(err);
            });
      },
      viewDetail(index) {
         this.selectedProduct = this.products[index];
         this.$refs["modal-scrollable"].show();
      },
      applyNow(slug) {
         this.$router.push(`/basic/${slug}`);
      },
   },
};
</script>